import React from 'react'

const CheckboxGroup = (props) => {
  const { children, name, value: checkedValues, onChange } = props

  const onCheckboxChange = (
    checkboxValue,
    event,
  ) => {
    if (event.target.checked) {
      onChange(checkedValues.concat(checkboxValue))
    } else {
      onChange(checkedValues.filter((v) => v !== checkboxValue))
    }
  }

  const Checkbox = (checkboxProps) => {
    const { value: cbValue, disabled, ...rest } = checkboxProps

    const checked = checkedValues ? checkedValues.indexOf(cbValue) >= 0 : false

    return (
      <input
        {...rest}
        type="checkbox"
        name={name}
        disabled={disabled}
        checked={checked}
        onChange={onCheckboxChange.bind(null, cbValue)}
        value={cbValue}
      />
    )
  }

  return children(Checkbox)
}

export default CheckboxGroup