import React from 'react';
import { createRoot } from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';

import SRoutes from './routes';

import { store } from './redux/store'
import { Provider } from 'react-redux';

import * as serviceWorker from './serviceWorker';

import './index.scss'

const root = createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <BrowserRouter>
                <SRoutes />
        </BrowserRouter>
    </Provider>);

serviceWorker.unregister();
