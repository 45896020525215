class Config {
    constructor() {        

        this.apiServer = "https://engine.bigbuddy.io";
        this.previewServer = "https://preview.studio/";
        
        if (process.env.NODE_ENV === "development" || window.location.href.indexOf("beta") > -1 || window.location.href.indexOf("test") > -1) {

            this.apiServer = "https://staging1.bigbuddy.io";
            this.previewServer = "https://test.preview.studio/";    
            //this.previewServer = "http://localhost:3001/"            

        }
       
        this.ROOT_URL = this.apiServer;
    }
}
export default (new Config()); 