import React, { lazy, Suspense, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import App from "../components/app";

import { Loader } from '../components/react-overlay-loader';
import withGA from "../helpers/GA/withGA-HOC";

import Error404 from '../components/404/'

import ErrorBoundary from '../components/error-boundary'
import ReactGA from 'react-ga4';

/* Showcase */
const Showcase = lazy(() => import('../components/showcase'))



const SRoutes = () => {

    
let location = useLocation();

useEffect(() => {
    if (process.env.NODE_ENV !== "development"){
        ReactGA.initialize('G-VV3HHGPPFJ');
        ReactGA.send({ hitType: "pageview", page:  location.pathname + location.search});
    }
}, [location]);

    return (
             <App>
                <ErrorBoundary>
                    <Suspense fallback={<Loader containerStyle={{ backgroundColor: "transparent" }} loading={true} fullPage text="Loading..." />}>
                        <Routes>                           
                            {/* Showcase */}
                            <Route exact path="/" element={<Showcase />} />
                            <Route exact path="/filter" element={<Showcase />} />
                            <Route exact path="/404Error" element={<Error404 />} />
                            <Route path="*" render={() => <Navigate to=""/>} />    
                        </Routes>
                    </Suspense>
                </ErrorBoundary>


            </App>
    );
};

export default withGA(SRoutes);
