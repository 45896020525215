import { combineReducers } from 'redux';

import { showcase } from './showcase/showcase.reducer'

const rootReducer = combineReducers({
    showcase,
});

//export default rootReducer;
const reducer = (state, action) => (
    rootReducer(state, action)
)
export default reducer
